/* eslint-disable no-unused-vars */
import offersService from '../services/offers.service';
import campaignsService from "@/services/campaigns.service";

const initialState = {
    offers: null,
    file: null,
    offersIds: null,
    promotedOfferIds: null,
    offer: null,
    customPayouts: null,
    offersForFilters: null
};

export const offers = {
    namespaced: true,
    state: initialState,
    actions: {
        editCustomPayout({ commit }, data) {
            return offersService.editCustomPayout(data);
        },
        async getCustomPayouts({ commit }, options) {
          const customPayouts = await offersService.getCustomPayouts(options);
            commit('getCustomPayoutsSuccess', customPayouts);
        },
        async getCustomPayout({ commit }, options) {
            return await offersService.getCustomPayout(options);
        },
        async createCustomPayout({ commit }, data) {
            await offersService.createCustomPayout(data);
        },
        deleteCustomPayout({ commit }, id) {
            return offersService.deleteCustomPayout(id);
        },
        async shareOffer({ commit }, data) {
            await offersService.shareOffer(data);
        },
        async getOffers({ commit }, options) {
            const offers = await offersService.getOffers(options);
            commit('getOffersSuccess', offers);
        },
        async getOffersForFilter({ commit }, options) {
            const offers = await offersService.getOffers(options);
            commit('getOffersForFilterSuccess', offers);
        },

        async getOffersIds({ commit }, options) {
            if(null === this.state.offers.offersIds) {
                const offersIds = await offersService.getOffersIds(options);
                commit('getOffersIdsSuccess', offersIds);
            }
        },

        async addBannerFiles({ commit }, file) {
            const fileResponse = (await offersService.addBannerFiles(file, this.state.offers.offer.id)).url;
            commit('addBannerFilesSuccess', fileResponse)
        },

        async addLandingPageFiles({ commit }, file) {
            const fileResponse = (await offersService.addLandingPageFiles(file, this.state.offers.offer.id)).url;
            commit('addFileSuccess', fileResponse)
        },

        async addOffer({ commit }, newOffer) {
            const offer =await offersService.addOffer(newOffer);
            commit('addOfferSuccess', offer)
        },

        async deleteOffer({ commit }, offerId) {
            await offersService.deleteOffer(offerId);
        },

        async getOffer({ commit }, offerId) {
            const offer = await offersService.getOffer(offerId);
            commit('getOfferSuccess', offer)
        },

        async editOffer({ commit }, modifiedOffer) {
            await offersService.editOffer(modifiedOffer);
        },
        async generateLinks({commit}, offer) {
            await offersService.generateLinks(offer);
        },
    },
    mutations: {
        getFileSuccess(state, file) {
            state.file = file;
        },
        getCustomPayoutsSuccess(state, customPayouts) {
            state.customPayouts = customPayouts;
        },
        addOfferSuccess(state, offer) {
            state.offer = offer
        },
        getOffersSuccess(state, offers) {
            state.offers = offers;
        },
        getOffersForFilterSuccess(state, offers) {
            state.offersForFilters = offers;
        },
        getOffersIdsSuccess(state, offersIds) {
            state.offersIds = offersIds;
        },
        getOfferSuccess(state, offer) {
            state.offer = offer;
        },
        setOfferForPage(state, offer) {
            state.offer = offer;
        }
    },
    getters: {
        getFile(state) {
            return state.file
        },
        getOffersData(state) {
            return state.offers?.data;
        },
        getCustomPayoutsData(state) {
            return state.customPayouts?.data;
        },
        getOffersForFilter(state){
            return state.offersForFilters?.data;
        },
        getOffersIds(state) {
            return state.offersIds?.data;
        },
        getOffersMeta(state) {
            return state.offers?.meta;
        },
        getOffer(state){
            console.log("getOffer", state.offer)
            return state.offer;
        }
    }
}

