<template>
  <div v-if="userProfile && userProfile?.roles[0].name !== 'affiliate'" class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        :custom-label="customLabelForWeb"
        v-model="searchUserId"
        placeholder="Web"
        :options="userOptions ?? []"
        select-label='+'
        deselect-label="-"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    VueMultiselect,
  },

  data() {
    return {
      searchUserId: null,
    };
  },

  props: {
    defaultWebId: {
      type: String,
      default: () => ''
    }
  },

  computed: {
    ...mapGetters({
      userOptions: "users/getWebsIdsWithNames",
      userProfile: "profile/getUserProfile",
    }),
  },
  watch: {
    searchUserId: {
      handler: "updateSearchUserId"
    },
    userProfile(newVal) {
      if (newVal && (newVal?.roles[0].name === 'admin' || newVal?.roles[0].name === 'affiliate_manager')) {
        this.$store.dispatch("users/getWebsIdsWithNames");
      }
    }
  },

  async created() {
    await this.$store.dispatch("users/getWebsIdsWithNames");
    if ('' !== this.defaultWebId && null !== this.defaultWebId) {
      console.log('this.defaultWebId', this.defaultWebId);
      this.updateSearchUserId(this.userOptions.find(option => option.ext_id === this.defaultWebId));
    }

  },
  methods: {
    customLabelForWeb({name, ext_id}) {
      let string = ''
      if (null !== ext_id) {
        string += `${ext_id}`;
      }

      if (null !== name) {
        string += ` ${name}`;
      }

      return string;
    },
    // Method to update searchUserId and emit its value to the parent
    updateSearchUserId(value) {
      this.searchUserId = value;
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'web_id'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'web_id', value: value.ext_id})
      }

      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
