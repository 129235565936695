<template>
  <div class="card card-filters">
    <!-- Card header -->
    <div class="card-header border-bottom">
      <div class="campaign d-flex align-items-center">
        <div class="col-6">
          <h5 class="mb-0">Filters</h5>
        </div>
      </div>
    </div>

    <!-- Card body -->
    <div class="card-body">
      <div class="row mt-sm-2">

        <ProductNameFilter @update:updateFilters="updateFilters"/>

        <OfferTypeFilter @update:updateFilters="updateFilters"/>

        <GeoFilter @update:updateFilters="updateFilters"/>

        <CampaignStatusFilter @update:updateFilters="updateFilters"/>

        <WebIDFilter v-if="role !== 'affiliate'" @update:updateFilters="updateFilters"/>

        <OffersNicheFilter @update:updateFilters="updateFilters"/>

        <OffersMetricTypeFilter @update:updateFilters="updateFilters"/>

        <UsersPartnerIDFilter v-if="role !== 'affiliate'" @update:updateFilters="updateFilters"/>

        <LeadPhoneNumberFilter @update:updateFilters="updateFilters"/>
      </div>
    </div>
  </div>
</template>
<script>
import GeoFilter from "@/components/Filters/GeoFilter.vue"
import OfferTypeFilter from "@/components/Filters/OfferTypeFilter.vue"
import WebIDFilter from "@/components/Filters/WebIDFilter.vue"
import OffersNicheFilter from "@/components/Filters/OffersNicheFilter.vue";
import OffersMetricTypeFilter from "@/components/Filters/OffersMetricTypeFilter.vue";
import UsersPartnerIDFilter from "@/components/Filters/UsersPartnerIDFilter.vue";
import LeadPhoneNumberFilter from "@/components/leads/filters/LeadPhoneNumberFilter.vue";
import ProductNameFilter from "@/components/Filters/ProductNameFilter.vue";
import CampaignStatusFilter from "@/components/Filters/CampaignStatusFilter.vue";

export default {
  name: 'CampaignsFilters',
  components: {
    CampaignStatusFilter,
    ProductNameFilter,
    LeadPhoneNumberFilter,
    UsersPartnerIDFilter,
    OffersMetricTypeFilter,
    OffersNicheFilter, GeoFilter, OfferTypeFilter, WebIDFilter},
  props: {
    updateFilters: {},
    role: {
      type: String,
      default: ''
    }
  }
}
</script>
