<template>
  <div class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="searchAffiliateManagerId"
        placeholder="Affiliate Manager"
        :options="userOptions ?? []"
        label="name"
        track-by="id"
        select-label='+'
        deselect-label="-"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    VueMultiselect,
  },

  data() {
    return {
      searchAffiliateManagerId: null,
    };
  },

  computed: {
    ...mapGetters({
      userOptions: "users/getAffiliateManagersIds",
    }),
  },
  watch: {
    searchAffiliateManagerId: {
      handler: "updateSearchAffiliateManagerId"
    },
  },
  methods: {
    // Method to update searchAffiliateManagerId and emit its value to the parent
    updateSearchAffiliateManagerId(value) {
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'affiliate_manager_ext_id'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'affiliate_manager_ext_id', value: value.ext_id})
      }

      this.$emit('update:updateFilters', filter);
    }
  },

  mounted() {
    this.$store.dispatch("users/getAffiliateManagerIds");
  },
};
</script>

<style scoped>
</style>
