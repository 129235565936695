import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async getLeads(params) {
        const response = await axios.get(
            "/leads/-actions/leads-list", {
                headers: authHeader(),
                params: params
            });

        return {data: response.data.data, meta: response.data};
    },

    //Dates with leads on dashboard
    async getDashboardStats(params) {
        const response = await axios.get("/leads/-actions/dashboard-stats", { headers: authHeader(), params: params });
        return { data: response.data};
    },

    async addLead(lead) {
        lead.type = "leads"
        const newJson = dataFormatter.serialize({ stuff: lead })
        const response = await axios.post("/leads", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteLead(leadId) {
        await axios.delete("/leads/" + leadId, { headers: authHeader() });
    },

    async getLead(leadId) {
        const response = await axios.get("/leads/" + leadId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editLead(lead) {
        lead.type = "leads"
        const newJson = dataFormatter.serialize({ stuff: lead })
        const response = await axios.patch("/leads/" + lead.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




