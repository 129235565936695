/* eslint-disable no-unused-vars */
import router from "@/router"
import tooltip from "@/assets/js/tooltip";
import removeTooltip from "@/assets/js/removeTooltip";
import Swal from "sweetalert2";
import showSwal from "./showSwal.js";
import store from "@/store";

var deleteState = false;
var paramsCall = {
    sort: "created_at",
    query: "",
    nr: "1",
    perpage: "25"
};

function showSwalDelete(id, table, deletePath, getPath, textDelete, defaultSort = null) {
    showSwal.methods.showSwalConfirmationDelete()
        .then(async (result) => {
            if (defaultSort) {
                paramsCall.sort = defaultSort;
            }
            if (result.isConfirmed) {
                try {
                    deleteState = true;
                    await store.dispatch(deletePath, id)
                    await store.dispatch(getPath, {
                        ...(paramsCall.sort ? {sort: paramsCall.sort} : {}),
                        filter: {
                            ...(paramsCall.query ? paramsCall.query : {}),
                        },
                        page: {
                            number: paramsCall.nr,
                            size: paramsCall.perpage,
                        },
                    });
                    showSwal.methods.showSwal({
                        type: "success",
                        message: textDelete
                    });
                } catch (error) {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: error.response.data.errors[0].detail
                    });
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.dismiss;
            }
        });
}

function showTooltip(event, message) {
    // Create a tooltip element
    const tooltip = document.createElement('div');
    tooltip.classList.add('tooltip');
    tooltip.textContent = message;

    // Style the tooltip
    tooltip.style.position = 'absolute';
    tooltip.style.zIndex = '1000';
    tooltip.style.backgroundColor = 'rgba(0, 0, 0, 0.75)';
    tooltip.style.color = 'white';
    tooltip.style.padding = '5px 10px';
    tooltip.style.borderRadius = '5px';
    tooltip.style.pointerEvents = 'none'; // Allow pointer events to pass through

    // Set tooltip position near the cursor
    tooltip.style.left = `${event.clientX + 10}px`;
    tooltip.style.top = `${event.clientY + 10}px`;

    // Append the tooltip to the document body
    document.body.appendChild(tooltip);

    // Remove the tooltip after 2 seconds
    setTimeout(() => {
        tooltip.remove();
    }, 2000);
}

function copyToClipboardFallback(url, event) {

    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.style.position = 'absolute';
    tempInput.style.opacity = 0;
    tempInput.value = url;

    // Append it to the document body
    document.body.appendChild(tempInput);

    // Select the input value and copy it
    tempInput.select();
    document.execCommand('copy');

    // Remove the temporary input element from the DOM
    document.body.removeChild(tempInput);

    showSwal.methods.showSwal({
        type: "success",
        message: 'Copied!'
    });
    // Show success message
    showTooltip(event, 'Copied!');
}

export default {
    methods: {
        eventToCall(options) {
            if (deleteState) {
                removeTooltip();
                deleteState = false;
            }
            tooltip(store.state.bootstrap);
            var copyToClipboard = document.querySelectorAll('.copy-to-clipboard');
            copyToClipboard.forEach(function (copyToClipboard) {
                copyToClipboard.addEventListener('click', function (event) {
                    // Prevent the default behavior of the link (navigating to a URL)
                    event.preventDefault();

                    // Get the URL of the clicked link
                    const url = copyToClipboard.getAttribute('data-url');

                    if ('clipboard' in navigator) {
                        // The clipboard API is supported
                        navigator.clipboard.writeText(url)
                            .then(() => {
                                showSwal.methods.showSwal({
                                    type: "success",
                                    message: 'Copied!'
                                });
                            })
                            .catch((err) => {
                                showSwal.methods.showSwal({
                                    type: "error",
                                    message: 'Copy failed!'
                                });
                            });
                    } else {
                        copyToClipboardFallback(url, event);
                    }
                })
            });
            var campaignWebIds = document.querySelectorAll('.campaign-list-web-id');
            campaignWebIds.forEach(function (campaignWebId) {
                campaignWebId.addEventListener('click', function () {
                    router.push({name: "Web Campaigns Dashboard", params: {webId: this.id}});
                })
            });
            var offers = document.querySelectorAll('.offer-name');
            offers.forEach(function (offer) {
                offer.addEventListener('click', function () {
                    router.push({name: "Offer Page", params: {offerId: this.id}});
                })
            });

            var activeCampaigns = document.querySelectorAll('.active-campaigns-for-offer');
            activeCampaigns.forEach(function (activeCampaign) {
                activeCampaign.addEventListener('click', function () {
                    router.push({name: "Campaigns List", params: {offerId: this.id}});
                })
            });

            var acceptCampaignButtons = document.querySelectorAll('.accept-campaign');
            acceptCampaignButtons.forEach(function (acceptCampaignButton) {
                acceptCampaignButton.addEventListener('click', function () {
                    router.push({name: "Accept Campaign", params: {campaignId: this.id}});
                })
            });

            var promoters = document.querySelectorAll('.redirect-to-dashboard-filtered-by-offer-id');
            promoters.forEach(function (promoter) {
                promoter.addEventListener('click', function () {
                    router.push({name: "Webs Dashboard", params: {offerId: this.id}});
                })
            });

            var redirectToLeadsBasedOnWithdrawals = document.querySelectorAll('.redirect-to-leads-based-on-withdrawal');
            redirectToLeadsBasedOnWithdrawals.forEach(function (redirectToLeadsBasedOnWithdrawal) {
                redirectToLeadsBasedOnWithdrawal.addEventListener('click', function () {
                    router.push({name: "Leads List", params: {withdrawalId: this.id}});
                })
            });

            var redirectToLeadsBasedOnCampaigns = document.querySelectorAll('.redirect-to-leads-based-on-campaign');
            redirectToLeadsBasedOnCampaigns.forEach(function (redirectToLeadsBasedOnCampaign) {
                redirectToLeadsBasedOnCampaign.addEventListener('click', function () {
                    router.push({name: "Leads List", params: {campaignId: this.id}});
                })
            });

            var redirectToLinkGenerator = document.querySelectorAll('.redirect-to-campaign-link-generator');
            redirectToLinkGenerator.forEach(function (redirectToLinkGenerator) {
                redirectToLinkGenerator.addEventListener('click', function () {
                    router.push({name: "Campaign Link Generator", params: {campaignId: this.id}});
                })
            });

            var redirectToOfferLinkGenerator = document.querySelectorAll('.redirect-to-offer-link-generator');
            redirectToOfferLinkGenerator.forEach(function (redirectToLinkGenerator) {
                redirectToLinkGenerator.addEventListener('click', function () {
                    router.push({name: "Offer Link Generator", params: {offerId: this.id}});
                })
            });

            var webPayouts = document.querySelectorAll('.web-payout');
            webPayouts.forEach(function (webPayout) {
                webPayout.addEventListener('click', function () {
                    router.push({name: "Leads List", params: {webId: this.id, leadStatus: 'approved'}});
                })
            });

            var approvedLeads = document.querySelectorAll('.redirect-to-leads');
            approvedLeads.forEach(function (approvedLead) {
                approvedLead.addEventListener('click', function () {
                    router.push({name: "Leads List", params: {serializedFilters: this.id}});
                })
            });

            var deleteButton = document.querySelectorAll('.delete-button');
            deleteButton.forEach(function (button) {
                button.addEventListener('click', async function () {
                    showSwalDelete(this.id, options.table, options.deletePath, options.getPath, options.textDelete, options.defaultSort);
                })
            });

            var buttons = document.querySelectorAll('.actionButton');
            buttons.forEach(function (button) {
                button.addEventListener('click', function () {
                    if (options.redirectPath == "Edit User") {
                        if (this.id == options.myUserId) {
                            removeTooltip();
                            router.push({name: 'User Profile'});
                        } else {
                            removeTooltip();
                            router.push({name: options.redirectPath, params: {userId: this.id}});
                        }
                    } else {
                        removeTooltip();
                        router.push({name: options.redirectPath, params: {id: this.id}});
                    }
                })

            });
            var selectors = document.querySelectorAll('.change-withdrawal-status');
            selectors.forEach(function (selector) {
                selector.addEventListener('change', async function (e) {
                    await store.dispatch("withdrawals/editWithdrawal", {
                        id: e.target.id,
                        withdrawal_status: e.target.value
                    });
                })
                selector.addEventListener('change', function () {
                    this.className = 'btn-for-event-table btn-round custom-select change-withdrawal-status ' + this.value;
                });
            })

            selectors = document.querySelectorAll('.deactivate-user');
            selectors.forEach(function (selector) {
                const aElement = selector.querySelector('a');
                const pElement = selector.querySelector('p');

                aElement.addEventListener('click', async function (e) {
                    await store.dispatch("users/editUser", {
                        id: this.id,
                        status: "banned"
                    });
                    pElement.textContent = 'banned';
                    this.classList.add("d-none");
                })
            })

            selectors = document.querySelectorAll('.change-user-status');
            selectors.forEach(function (selector) {
                selector.addEventListener('change', async function (e) {
                    await store.dispatch("users/editUser", {
                        id: e.target.id,
                        status: e.target.value
                    });
                })
            })

            selectors = document.querySelectorAll('.download-invoice-for-withdrawal');
            selectors.forEach(function (selector) {
                selector.addEventListener('click', async function (e) {
                    await store.dispatch("withdrawals/getInvoice", e.target.id);
                })
            })

            selectors = document.querySelectorAll('.change-affiliate-manager');
            selectors.forEach(function (selector) {
                selector.addEventListener('change', async function (e) {
                    let affiliate_manager = {};
                    if ("null" === e.target.value) {
                        affiliate_manager = null
                    } else {
                        affiliate_manager = {
                            type: 'users',
                            id: e.target.value
                        }
                    }
                    await store.dispatch("users/editUser", {
                        id: e.target.id,
                        affiliate_manager: affiliate_manager,
                        relationshipNames: ['affiliate_manager']
                    });
                })
            })

            selectors = document.querySelectorAll('.change-partner');
            selectors.forEach(function (selector) {
                selector.addEventListener('change', async function (e) {
                    let partner = {};
                    if ("null" === e.target.value) {
                        partner = null
                    } else {
                        partner = {
                            type: 'users',
                            id: e.target.value
                        }
                    }
                    await store.dispatch("users/editUser", {
                        id: e.target.id,
                        partner: partner,
                        relationshipNames: ['partner']
                    });
                })
            })

            var downloadLp = document.querySelectorAll('.download-lp');
            downloadLp.forEach(function (button) {
                button.addEventListener('click', async function () {
                    await store.dispatch("campaigns/downloadLandingPage", this.id);
                })
            });

            var askForCampaignButtons = document.querySelectorAll('.ask-for-campaign');
            askForCampaignButtons.forEach(function (button) {
                button.addEventListener('click', function () {
                    router.push({name: "Request Access", params: {offerId: this.id}});
                })
            });

            var redirectToOfferPage = document.querySelectorAll('.redirect-to-offer-page');
            redirectToOfferPage.forEach(function (button) {
                button.addEventListener('click', function () {
                    router.push({name: "Offer Page", params: {offerId: this.id}});
                })
            });

            var banUserSelections = document.querySelectorAll('.ban-user');
            banUserSelections.forEach(function (button) {
                button.addEventListener('click', async function (e) {
                    await store.dispatch("users/editUser", {
                        id: this.id,
                        status: 'banned'
                    });
                    showSwal.methods.showSwal({
                        type: "success",
                        message: 'User banned'
                    });
                })
            });

            var generateCampaignPostbackUrl = document.querySelectorAll('.generate-campaign-postback-url');
            generateCampaignPostbackUrl.forEach(function (button) {
                button.addEventListener('click', function () {
                    router.push({name: "Campaign Postback Url Generator", params: {campaignId: this.id}});
                })
            });

            document.querySelectorAll('.users-payout-override .btn').forEach(button => {
                button.addEventListener('click', async function (e) {
                    // Find the input sibling in the same .input-group container
                    let inputValue = this.previousElementSibling.value;
                    await store.dispatch("users/editUser", {
                        id: e.target.id,
                        payout_override: Number(inputValue)
                    });
                    // Perform any additional actions with inputValue here
                });
            });

            var customPayoutContainers = document.querySelectorAll('.change-custom-payout-container');
            customPayoutContainers.forEach(function (container) {
                //get id
                const id = container.id;
                //get edit button inside this container
                const editButton = container.querySelector('.edit-custom-payout-button');
                //get save button inside this container
                const saveButton = container.querySelector('.save-custom-payout-button');
                //get cancel button inside this container
                const cancelButton = container.querySelector('.cancel-custom-payout-changes-button');
                //get name element inside this container
                const payoutElement = container.querySelector('.editable-payout span');
                // Add event listeners
// Add event listeners
                editButton.addEventListener('click', function () {
                    // Switch to edit mode
                    payoutElement.contentEditable = 'true';
                    editButton.style.display = 'none';
                    saveButton.style.display = 'inline-block';
                    cancelButton.style.display = 'inline-block';

                    // Store the original name
                    payoutElement.dataset.originalName = payoutElement.textContent;

                    // If the nameElement is empty, add a non-breaking space
                    if (payoutElement.textContent.trim() === '') {
                        payoutElement.innerHTML = '&nbsp;';
                    }

                    // Add styles to make it look like an input field
                    payoutElement.style.border = '1px solid #ccc';
                    payoutElement.style.padding = '5px';
                    payoutElement.style.backgroundColor = '#fff';
                    payoutElement.style.minWidth = '100px !important'
                });

                cancelButton.addEventListener('click', function () {
                    // Cancel changes
                    payoutElement.contentEditable = 'false';
                    editButton.style.display = 'inline-block';
                    saveButton.style.display = 'none';
                    cancelButton.style.display = 'none';

                    // Revert the text content to the original name
                    payoutElement.textContent = payoutElement.dataset.originalName;

                    // Remove the styles
                    payoutElement.style.border = 'none';
                    payoutElement.style.padding = '0';
                    payoutElement.style.backgroundColor = 'transparent';
                });

                saveButton.addEventListener('click', async function () {
                    // Save changes
                    payoutElement.contentEditable = 'false';
                    editButton.style.display = 'inline-block';
                    saveButton.style.display = 'none';
                    cancelButton.style.display = 'none';

                    // Remove the styles
                    payoutElement.style.border = 'none';
                    payoutElement.style.padding = '0';
                    payoutElement.style.backgroundColor = 'transparent';

                    store.dispatch("offers/editCustomPayout", {
                        id: id, // Use the id variable here
                        payout: parseInt(payoutElement.textContent)
                    }).then(() => {
                        showSwal.methods.showSwal({
                            type: "success",
                            message: 'Saved!'
                        });
                    }).catch(() => {
                        showSwal.methods.showSwal({
                            type: "error",
                            message: 'Error!'
                        });
                    });
                    // Here you can add code to save the changes to the server
                    console.log('Saved changes: ' + payoutElement.textContent);
                });
            })

            var editCampaignButtons = document.querySelectorAll('.edit-campaign');
            editCampaignButtons.forEach(function (button) {
                button.addEventListener('click', function () {
                    router.push({name: "Edit Campaign", params: {campaignId: this.id}});
                })
            });
            var campaignNameContainers = document.querySelectorAll('.change-campaign-name-container');
            campaignNameContainers.forEach(function (container) {
                //get id
                const id = container.id;
                //get edit button inside this container
                const editButton = container.querySelector('.edit-campaign-name-button');
                //get save button inside this container
                const saveButton = container.querySelector('.save-campaign-name-button');
                //get cancel button inside this container
                const cancelButton = container.querySelector('.cancel-campaign-name-changes-button');
                //get name element inside this container
                const nameElement = container.querySelector('.editable-name span');
                // Add event listeners
// Add event listeners
                editButton.addEventListener('click', function () {
                    // Switch to edit mode
                    nameElement.contentEditable = 'true';
                    editButton.style.display = 'none';
                    saveButton.style.display = 'inline-block';
                    cancelButton.style.display = 'inline-block';

                    // Store the original name
                    nameElement.dataset.originalName = nameElement.textContent;

                    // If the nameElement is empty, add a non-breaking space
                    if (nameElement.textContent.trim() === '') {
                        nameElement.innerHTML = '&nbsp;';
                    }

                    // Add styles to make it look like an input field
                    nameElement.style.border = '1px solid #ccc';
                    nameElement.style.padding = '5px';
                    nameElement.style.backgroundColor = '#fff';
                    nameElement.style.minWidth = '100px !important'
                });

                cancelButton.addEventListener('click', function () {
                    // Cancel changes
                    nameElement.contentEditable = 'false';
                    editButton.style.display = 'inline-block';
                    saveButton.style.display = 'none';
                    cancelButton.style.display = 'none';

                    // Revert the text content to the original name
                    nameElement.textContent = nameElement.dataset.originalName;

                    // Remove the styles
                    nameElement.style.border = 'none';
                    nameElement.style.padding = '0';
                    nameElement.style.backgroundColor = 'transparent';
                });

                saveButton.addEventListener('click', async function () {
                    // Save changes
                    nameElement.contentEditable = 'false';
                    editButton.style.display = 'inline-block';
                    saveButton.style.display = 'none';
                    cancelButton.style.display = 'none';

                    // Remove the styles
                    nameElement.style.border = 'none';
                    nameElement.style.padding = '0';
                    nameElement.style.backgroundColor = 'transparent';

                    await store.dispatch("campaigns/editCampaign", {
                        id: id, // Use the id variable here
                        name: nameElement.textContent
                    });
                    // Here you can add code to save the changes to the server
                    console.log('Saved changes: ' + nameElement.textContent);
                });
            });
        },

        actionAcceptCampaign(campaign, text) {
            store.commit('campaigns/setCampaignForAcceptance', campaign);
            var actionEdit = `
              <a id="${campaign.id}" class="accept-campaign cursor-pointer me-3" data-bs-delay="0" title="${text}">
                <i class="fas fa-solid fa-check text-success fa-x"></i>
              </a>`;
            return actionEdit;
        },

        actionEditCampaignButton(id, text) {
            var actionEdit = `
              <a id="${id}" class="cursor-pointer edit-campaign me-3" data-bs-delay="0" title="${text}">
                <i class="fas fa-solid fa-edit text-success fa-x"></i>
              </a>`;
            return actionEdit;
        },

        generateCampaignPostbackUrl(id, text) {
            var actionEdit = `
              <a id="${id}" class="generate-campaign-postback-url cursor-pointer me-3" data-bs-delay="0" title="${text}">
                <i class="fas fa-solid fa-link text-success fa-x"></i>
              </a>`;
            return actionEdit;
        },

        actionChangeUserPayoutOverride(id, text) {
            var actionEdit = `
<div class="d-flex align-items-center users-payout-override" style="height: 38px;"> <!-- Adjust height as needed -->
    <input type="number" value="${text}" name="exampleNumber" id="${id}" class="form-control me-2" style="width: 50px;">
    <button id="${id}" type="button" class="btn-for-event-table btn-success btn-sm" style="margin-bottom: 0;" >Update</button>
</div>
            `
            ;

            return actionEdit;
        },
        actionChangeUserStatusForPartner(id, text) {
            var actionEdit = `
<div class="col-lg-5 col-md-6 col-sm-3">
  <select id=${id} class="btn-for-event-table btn-round custom-select change-user-status" data-style="btn-for-event-table btn-primary btn-round">`;
            actionEdit += `
                <option selected>${text}</option>
                <option value="banned">Banned</option>`
            actionEdit += `
              </select>
            </div>`
            ;
            return actionEdit;
        },
        actionChangeUserStatus(id, text) {
            var actionEdit = `
<div class="col-lg-5 col-md-6 col-sm-3">
  <select id=${id} class="btn-for-event-table btn-round custom-select change-user-status" data-style="btn-for-event-table btn-primary btn-round">`;
            if (text === "waiting_for_verification") {
                actionEdit += `
                <option selected value="waiting_for_verification">Waiting for verification</option>
                <option value="active">Active</option>
                <option value="banned">Banned</option>`
            } else if (text === "active") {
                actionEdit += `
                <option value="waiting_for_verification">Waiting for verification</option>
                <option selected value="active">Active</option>
                <option value="banned">Banned</option>`
            } else {
                actionEdit += `
                <option value="waiting_for_verification">Waiting for verification</option>
                <option value="active">Active</option>
                <option selected value="banned">Banned</option>`
            }

            actionEdit += `
              </select>
            </div>`
            ;
            return actionEdit;
        },

        actionDeactivateUser(id, text) { //todo
            //it can make it inactive only
            if (text === "active") {
                return `
                <div class="deactivate-user">
                    <p id="user-status">${text}</p>
                    <a id="${id}" class="cursor-pointer me-3" data-bs-delay="0" title="Deactivate">
                        <i class="fas fa-ban text-success fa-x"></i>
                    </a>
                </div>
                `;
            } else {
                return `
              <p>${text}</p>`;
            }
        },

        actionChangeWithdrawalStatus(id, text) {
            const options = ['rejected', 'paid', 'waiting'];

            let actionEdit = `
<div class="col-lg-5 col-md-6 col-sm-3">
  <select id="${id}" class="btn-for-event-table btn-round custom-select change-withdrawal-status ${text}" data-style="btn-for-event-table btn-primary btn-round">
`;

            options.forEach(option => {
                const selected = option === text ? 'selected' : '';
                actionEdit += `<option class="${option}" ${selected} value="${option}">${option.charAt(0).toUpperCase() + option.slice(1)}</option>`;
            });

            actionEdit += `
  </select>
</div>
`;
            return actionEdit;
        },

        downloadInvoiceAttachedToWithdrawal(id) {
            return `<button id="${id}" type="button" class="btn-for-event-table btn-success btn-sm download-invoice-for-withdrawal" style="margin-bottom: 0;" >Download</button>`
        },

        actionChangeAffiliateManager(id, options, accountName) {
            let dropdownHTML = `
                <div class="col-lg-5 col-md-6 col-sm-3">
                   <select id=${id} class="btn-for-event-table btn-round custom-select change-affiliate-manager" data-style="btn-for-event-table btn-primary btn-round">
                `;
            // Add an empty option if defaultValue is null
            if (accountName === null) {
                dropdownHTML += '<option value=null selected></option>';
            } else {
                dropdownHTML += '<option value=null></option>';
            }

            options.forEach(option => {
                // Check if the current option is the default value
                const isSelected = option.name === accountName ? ' selected' : '';
                dropdownHTML += `<option value="${option.id}"${isSelected}>${option.name}</option>`;
            });

            dropdownHTML += '</select> </div>';
            return dropdownHTML;
        },

        actionChangePartner(id, options, accountName) {
            let dropdownHTML = `
                <div class="col-lg-5 col-md-6 col-sm-3">
                   <select id=${id} class="btn-for-event-table btn-round custom-select change-partner" data-style="btn-for-event-table btn-primary btn-round">
                `;
            // Add an empty option if defaultValue is null
            if (accountName === null) {
                dropdownHTML += '<option value=null selected></option>';
            } else {
                dropdownHTML += '<option value=null></option>';
            }
            options.forEach(option => {
                // Check if the current option is the default value
                const isSelected = option.name === accountName ? ' selected' : '';
                dropdownHTML += `<option value="${option.id}"${isSelected}>${option.name}</option>`;
            });

            dropdownHTML += '</select> </div>';
            return dropdownHTML;
        },
        actionGenerateLinks(id, text) {
            var actionGenerateLinks = `
              <a id="${id}" class="campaign-generate-links cursor-pointer me-3" data-bs-delay="0" title="${text}">
                <i class="fas fa-user-check text-success fa-x"></i>
              </a>`;
            return actionGenerateLinks;
        },

        actionVerifyUser(roleId, text) {
            var actionEdit = `
              <a id="${roleId}" class="actionButton cursor-pointer me-3" data-bs-delay="0" title="${text}">
                <i class="fas fa-user-check text-success fa-x"></i>
              </a>`;
            return actionEdit;
        },


        actionBanUserButton(id, text) {
            var actionEdit = `
              <a id="${id}" class="ban-user cursor-pointer me-3" data-bs-delay="0" title="${text}">
                <i class="fas fa-ban text-success fa-x"></i>
              </a>`;
            return actionEdit;
        },

        actionRedirectToOfferPage(offer, text) {
            store.commit('offers/setOfferForPage', offer);
            var actionEdit = `
              <a id="${offer.id}" class="redirect-to-offer-page cursor-pointer me-3" data-bs-delay="0" title="${text}">
                <i class="fa fa-eye text-success fa-x"></i>
              </a>`;
            return actionEdit;
        },

        actionEditButton(roleId, text) {
            var actionEdit = `
              <a id="${roleId}" class="actionButton cursor-pointer me-3" data-bs-delay="0" title="${text}">
                <i class="fas fa-user-edit text-success fa-x"></i>
              </a>`;
            return actionEdit;
        },

        redirectToDashboardFilteredByOffer(id, name) {
            var html = `
             <a id="${id}" class="redirect-to-dashboard-filtered-by-offer-id cursor-pointer" data-bs-delay="0" title="${name}">
                <i class="fas fa-home fa-x"></i>
              </a>`;
            return html;
        },

        redirectToActiveCampaignsForOffer(id, name) {
            var html = `
             <a id="${id}" class="active-campaigns-for-offer cursor-pointer" data-bs-delay="0" title="${name}">
                <i class="fas fa-route text-danger fa-x"></i>
              </a>`;
            return html;
        },

        navigateToLeads(filters, status, webId, text) {
            var localFilters = {...filters};
            if ('' !== status) {
                localFilters['lead_status'] = status;
            }
            localFilters['web_id'] = webId;
            var stringified = JSON.stringify(localFilters);
            var html = `
             <a id='${stringified}' class="redirect-to-leads" style="cursor: pointer;">
                  ${text}
                </a>`;
            return html;
        },

        redirectToLeadsBasedOnWithdrawal(withdrawal) {
            var html = `
              <a id="${withdrawal.id}" class="redirect-to-leads-based-on-withdrawal"  style="cursor: pointer;" title="Redirect to leads" data-bs-delay="0">
                <i class="fas fa-bookmark text-danger fa-x"></i>
              </a>`;
            return html;
        },

        redirectToLeadsBasedOnCampaign(id) {
            var html = `
              <a id="${id}" class="redirect-to-leads-based-on-campaign"  style="cursor: pointer;" title="Redirect to leads" data-bs-delay="0">
                <i class="fas fa-bookmark text-danger fa-x"></i>
              </a>`;
            return html;
        },

        downloadLP(id, text) {
            var html = `
              <a id="${id}" class="download-lp"  style="cursor: pointer;" title="${text}" data-bs-delay="0">
                <i class="fas fa-download text-danger fa-x"></i>
              </a>`;
            return html;
        },

        changeCustomPayout(id, payout) {
            // Create a container div
            let container = document.createElement('div');
            container.classList.add('editable-payout');
            container.classList.add('change-custom-payout-container');
            container.id = id;

            // Create the name element
            let payoutElement = document.createElement('span');
            payoutElement.textContent = payout;
            container.appendChild(payoutElement);

            // Create the edit button
            let editButton = document.createElement('i');
            editButton.classList.add('fas', 'fa-pencil-alt', 'edit-button', 'edit-custom-payout-button');
            container.appendChild(editButton);

            // Create the save button
            let saveButton = document.createElement('i');
            saveButton.classList.add('fas', 'fa-check', 'save-button', 'save-custom-payout-button');
            saveButton.style.display = 'none';
            container.appendChild(saveButton);

            // Create the cancel button
            let cancelButton = document.createElement('i');
            cancelButton.classList.add('fas', 'fa-times', 'cancel-button', 'cancel-custom-payout-changes-button');
            cancelButton.style.display = 'none';
            container.appendChild(cancelButton);

            // Return the container as a string of HTML
            return container.outerHTML;
        },

        changeCampaignName(id, name) {
            // Create a container div
            let container = document.createElement('div');
            container.classList.add('editable-name');
            container.classList.add('change-campaign-name-container');
            container.id = id;

            // Create the name element
            let nameElement = document.createElement('span');
            nameElement.textContent = name;
            container.appendChild(nameElement);

            // Create the edit button
            let editButton = document.createElement('i');
            editButton.classList.add('fas', 'fa-pencil-alt', 'edit-button', 'edit-campaign-name-button');
            container.appendChild(editButton);

            // Create the save button
            let saveButton = document.createElement('i');
            saveButton.classList.add('fas', 'fa-check', 'save-button', 'save-campaign-name-button');
            saveButton.style.display = 'none';
            container.appendChild(saveButton);

            // Create the cancel button
            let cancelButton = document.createElement('i');
            cancelButton.classList.add('fas', 'fa-times', 'cancel-button', 'cancel-campaign-name-changes-button');
            cancelButton.style.display = 'none';
            container.appendChild(cancelButton);

            // Return the container as a string of HTML
            return container.outerHTML;
        },

        redirectToApprovedLeads(id, payout) {
            var html = `
             <a id="${id}" class="web-payout cursor-pointer">
                  <span style="color: #4da24d; font-weight: 700">${payout}<span>
                </a>`;
            return html;
        },

        actionDeleteButton(roleId, text) {
            var actionDelete = `
              <a id="${roleId}" class="cursor-pointer  delete-button" data-bs-delay="0" title="${text}">
                <i class="fas fa-trash text-danger fa-x"></i>
              </a>`;
            return actionDelete;
        },

        deleteCustomPayout(payoutId, text) {
            var actionDelete = `
              <a id="${payoutId}" class="cursor-pointer delete-custom-payout-button" data-bs-delay="0" title="${text}">
                <i class="fas fa-trash text-danger fa-x"></i>
              </a>`;
            return actionDelete;
        },

        actionDeleteUserButton(roleId, text) {
            var actionDelete = `
              <a id="${roleId}" class="cursor-pointer delete-button" data-bs-delay="0" title="${text}">
                <i class="fas fa-trash text-danger fa-x"></i>
              </a>`;
            return actionDelete;
        },


        removeEvent() {
            var buttons = this.$el.querySelectorAll('.actionButton');
            buttons.forEach(function (button) {
                button.replaceWith(button.cloneNode(true));
            });
        },

        actionRedirectToCampaignLinkGenerate(id, text) {
            var actionDelete = `
              <a id="${id}" class="redirect-to-campaign-link-generator" data-bs-delay="0" title="${text}" style="cursor: pointer">
                <i class="fas fa-link text-danger fa-x"></i>
              </a>`;
            return actionDelete;
        },

        actionRedirectToOfferLinkGenerate(id, text) {
            var actionDelete = `
              <a id="${id}" class="redirect-to-offer-link-generator" data-bs-delay="0" title="${text}" style="cursor: pointer">
                <i class="fas fa-link text-danger fa-x"></i>
              </a>`;
            return actionDelete;
        },


        leadStatus(status) {
            if (status === 'Rejected' || status === 'Trashed') {
                //apply style with hash of the color

                return `<span class="font-weight-bold" style="color: #be3939">${status}</span>`;
            }
            if (status === 'Approved') {
                return `<span class="font-weight-bold" style="color: #108f10">${status}</span>`;
            }

            return `<span>${status}</span>`;
        },

        leadSub(sub) {
            return `<span class="subs" hidden>${sub}</span>`;
        },

        campaignPayout(payout) {
            return `<span style="color: #4da24d; font-weight: 700">${payout}</span>`;
        },
        apiKeyForCampaign(apiKey) {
            return `<a data-url="${apiKey}" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy"
               class="copy-to-clipboard d-block" title="api-key" style="cursor: pointer;">
                ${apiKey.substring(0, 10)}... <i class="fas fa-copy"></i>
            </a>`
        },
        landingPageLinks(prelanding_link, landing_link) {
            var links = `
                    <div class="container">
        <div class="row">
            <div class="col-12">
                ${'' !== prelanding_link ?
                `<a data-url="${prelanding_link}" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy" class="copy-to-clipboard d-block" title="Prelanding" style="cursor: pointer;">
                        <span class="font-weight-bold">P: </span>
                        ${prelanding_link} <i class="fas fa-copy"></i>
                    </a>`
                : ''}
                
                ${'' !== landing_link ?
                `<a data-url="${landing_link}" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy" class="copy-to-clipboard d-block" title="Landing" style="cursor: pointer;">
                        <span class="font-weight-bold">L: </span>
                        ${landing_link} <i class="fas fa-copy"></i>
                    </a>`
                : ''}
            </div>
        </div>
    </div>
                `;

            return links;
        },
    }
}
