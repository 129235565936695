import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import {auth} from "./auth.module"
import {roles} from "./roles.module"
import {users} from "./users.module"
import {tags} from "./tags.module"
import {categories} from "./categories.module"
import {items} from "./items.module"
import {profile} from "./profile.module"
import {offers} from "@/store/offers.module";
import {leads} from "@/store/leads.module";
import {withdrawals} from "@/store/withdrawals.module";
import {campaigns} from "@/store/campaigns.module";
import {products} from "@/store/products.module";
import {files} from "@/store/files.module";
import {filters} from "@/store/filters.module";

export default createStore({
  modules:{
    auth,
    roles,
    users,
    tags,
    categories,
    items,
    profile,
    offers,
    leads,
    products,
    withdrawals,
    campaigns,
    files,
    filters
  },
  state: {
    hideConfigButton: true,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    color: "success",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: true,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    setIsPinned(state, payload) {
      state.isPinned = payload;
    },
    color(state, payload) {
      state.color = payload;
    },
    navbarMinimize(state) {
      const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];

      if (sidenavShow.classList.contains("g-sidenav-pinned")) {
        sidenavShow.classList.remove("g-sidenav-pinned");
        sidenavShow.classList.add("g-sidenav-hidden");
        state.isPinned = true;
      } else {
        sidenavShow.classList.remove("g-sidenav-hidden");
        sidenavShow.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    }
  },
  actions: {
    setColor({ commit }, payload) {
      commit("color", payload);
    }
  },
  getters: {}
});
