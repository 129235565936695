<template>
  <div class="card card-filters">
    <!-- Card header -->
    <div class="card-header border-bottom">
      <div class="campaign d-flex align-items-center">
        <div class="col-6">
          <h5 class="mb-0">Filters</h5>
        </div>
      </div>

    </div>

    <!-- Card body -->
    <div class="card-body">
      <div class="row mt-sm-2">
        <LeadsDateFilter @update:update-filters="updateFilters"/>
        <LeadsStatusFilter :default-lead-status=defaultLeadStatus @update:update-filters="updateFilters"/>
        <GeoFilter @update:update-filters="updateFilters"/>
        <ProductNameFilter @update:update-filters="updateFilters"/>
        <WebIDFilter v-if="role !== 'affiliate'" :default-web-id=defaultWebId @update:update-filters="updateFilters"/>
        <UsersPartnerIDFilter v-if="role !== 'affiliate'" @update:update-filters="updateFilters"/>
        <LeadIdFilter @update:update-filters="updateFilters"/>
        <LeadExtUUIDFilter @update:update-filters="updateFilters"/>
        <LeadPhoneNumberFilter @update:update-filters="updateFilters"/>
        <LeadsSub1Filter @update:update-filters="updateFilters"/>
        <LeadsSub2Filter @update:update-filters="updateFilters"/>
        <LeadsSub3Filter @update:update-filters="updateFilters"/>
        <LeadsSub4Filter @update:update-filters="updateFilters"/>
      </div>
    </div>
  </div>
</template>
<script>
import GeoFilter from "@/components/Filters/GeoFilter.vue"
import LeadIdFilter from "@/components/Filters/LeadIdFilter.vue"
import LeadsDateFilter from "@/components/Filters/LeadsDateFilter.vue"
import ProductNameFilter from "@/components/Filters/ProductNameFilter.vue"
import WebIDFilter from "@/components/Filters/WebIDFilter.vue"
import LeadPhoneNumberFilter from "@/components/leads/filters/LeadPhoneNumberFilter.vue";
import UsersPartnerIDFilter from "@/components/Filters/UsersPartnerIDFilter.vue";
import LeadsStatusFilter from "@/components/Filters/LeadsStatusFilter.vue";
import LeadExtUUIDFilter from "@/components/Filters/LeadExtUUIDFilter.vue";
import LeadsSub1Filter from "@/components/Filters/LeadsSub1Filter.vue";
import LeadsSub2Filter from "@/components/Filters/LeadsSub2Filter.vue";
import LeadsSub3Filter from "@/components/Filters/LeadsSub3Filter.vue";
import LeadsSub4Filter from "@/components/Filters/LeadsSub4Filter.vue";

export default {
  name: 'LeadFilters',
  components: {
    LeadsSub4Filter,
    LeadsSub3Filter,
    LeadsSub2Filter,
    LeadsSub1Filter,
    LeadExtUUIDFilter,
    LeadsStatusFilter,
    UsersPartnerIDFilter,
    LeadPhoneNumberFilter, GeoFilter, LeadIdFilter, LeadsDateFilter, ProductNameFilter, WebIDFilter},
  props: {
    updateFilters: {},
    defaultFilters: {
      type: Object,
      default: () => null
    },
    role: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      defaultLeadStatus: null,
      defaultWebId: null
    }
  },
  created() {
    if (this.defaultFilters) {
      if (this.defaultFilters.lead_status) {
        this.defaultLeadStatus = this.defaultFilters.lead_status;
      }
      if (this.defaultFilters.web_id) {
        this.defaultWebId = this.defaultFilters.web_id;
      }
    }
  }
}
</script>
