import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const dataFormatter = new Jsona();

export default {
    async getPostback(params) {
        const response = await axios.get(
            "/campaigns/-actions/postback/", {
                headers: authHeader(),
                params: params
            });

        return {data: response.data};
    },
    async createPostback(data) {
        const response = await axios.post(
            "/campaigns/-actions/create-postback", {data},
            {
                headers: authHeader(),
            });

        return {data: response.data};
    },
    async getPostbacks(params) {
        const response = await axios.get(
            "/campaigns/-actions/postback", {
                headers: authHeader(),
                params: params
            });

        return {data: response.data.data, meta: response.data};
    },
    async downloadLandingPage(campaignId) {
        try {
            const getUrl = "/campaigns/-actions/download-landing-page/" + campaignId;

            const response = await axios.get(getUrl, {
                responseType: 'blob', // Set response type to blob to handle binary data
            });

            // Create a URL for the binary data
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a link element
            const link = document.createElement('a');
            link.href = url;

            // Set the download attribute with a filename
            link.setAttribute('download', 'landing-page-files.zip');

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error downloading the file', error);
        }
    },
    async getCampaigns(params) {
        const response = await axios.get(
            "/campaigns" +
            "?include=promoter,leads,offer&", {
                headers: authHeader(),
                params: params
            });
        return {data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page};
    },

    async myCampaigns(params) {
        const response = await axios.get(
            "/campaigns/-actions/my-campaigns" +
            "?include=promoter,leads,offer&", {
                headers: authHeader(),
                params: params
            });

        return {data: response.data.data, meta: response.data};
    },

    async getDomains(params) {
        const response = await axios.get(
            "/campaigns/-actions/domains", {
                headers: authHeader(),
                params: params
            });

        return {data: response.data};
    },


    async getCampaignsAdmin(params) {
        const response = await axios.get(
            "/campaigns/-actions/campaigns-list", {
                headers: authHeader(),
                params: params
            });

        return {data: response.data.data, meta: response.data};
    },

    async getCampaignsForDashboard(params) {
        const response = await axios.get(
            "/campaigns/-actions/campaigns-for-dashboard", {
                headers: authHeader(),
                params: params
            });
        return {data: response.data.data, meta: response.data};
    },

    async addCampaign(campaign) {
        campaign.type = "campaigns"
        const newJson = dataFormatter.serialize({stuff: campaign, includeNames: ['offer']})
        const response = await axios.post("/campaigns", newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async deleteCampaign(campaignId) {
        const now = new Date();
        const campaign = {};
        campaign.type = "campaigns"
        campaign.id = campaignId;
        campaign.deletedAt = now.toISOString();
        const newJson = dataFormatter.serialize({stuff: campaign})
        await axios.patch("/campaigns/" + campaignId, newJson, {headers: authHeader()});
    },

    async getCampaignSimple(campaignId) {
        const response = await axios.get("/campaigns/" + campaignId + "?include=offer", {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async getCampaign(campaignId) {
        const response = await axios.get("/campaigns/" + campaignId + "?include=promoter,leads,offer,offer.product,offer.product.files&", {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async editCampaign(campaign) {
        campaign.type = "campaigns"
        const newJson = dataFormatter.serialize({stuff: campaign})
        const response = await axios.patch("/campaigns/" + campaign.id, newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async generateLinks(campaign) {
        campaign.type = "campaigns"
        const newJson = dataFormatter.serialize({stuff: campaign})
        const response = await axios.patch("/campaigns/" + campaign.id + "/-actions/generate-links", newJson, {headers: authHeader()});
        return response.data;
    },

    async acceptCampaign(campaign) {
        campaign.type = "campaigns"
        const newJson = dataFormatter.serialize({stuff: campaign})
        const response = await axios.patch("/campaigns/" + campaign.id + "/-actions/accept", newJson, {headers: authHeader()});
        return response.data;
    },
}
