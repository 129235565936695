import axios from 'axios';
import authHeader from './auth-header';

const BASE_URL = process.env.VUE_APP_BASE_URL

export default {

  async login(user) {
    var response = await axios.post('/login', {
      email: user.email,
      password: user.password
    });
    if (response.data.access_token) {
      localStorage.setItem('user', JSON.stringify(response.data.access_token));
    }
    return response.data;
  },

  async logout() {
    await axios.post("/logout", {}, { headers: authHeader() })
    localStorage.removeItem('user');
  },

  async register(user) {
    var response = await axios.post('/register', {
      first_name: user.first_name,
      last_name: user.last_name,
      telegram_account_name: user.telegram_account_name,
      email: user.email,
      password: user.password,
      password_confirmation: user.confirmPassword
    });
    if (response.data.access_token) {
      localStorage.setItem('user', JSON.stringify(response.data.access_token));
    }
    return response.data;
  },

  async passwordForgot(userEmail) {

    var response = await axios.post('/password-forgot', {
      redirect_url: BASE_URL + "/password-reset",
      email: userEmail
    })
    return response.status;
  },

  async passwordUpdate(passwordDTO) {
    var response = await axios.post('/password-update', {
      password: passwordDTO.newPassword,
      password_confirmation: passwordDTO.confirmPassword,
    }, { headers: authHeader() })
    return response.status;
  },

  async passwordReset(passwordDTO) {
    var response = await axios.post('/password-reset', {
      password: passwordDTO.newPassword,
      password_confirmation: passwordDTO.confirmPassword,
      email: passwordDTO.email,
      token: passwordDTO.token
    })
    return response.status;
  }
}
