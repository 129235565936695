import eventTable from "@/mixins/eventTable";

const { leadStatus, leadSub } = eventTable.methods;

export function getLeadProperties(lead, role) {
    if (role === 'affiliate') {
        return [
            lead.created_at,
            lead.id,
            lead.external_uuid,
            lead.product_name,
            lead.offer_type,
            `<div ><span class="fi fi-${lead.geo.toLowerCase()} fis flag-span" title="${lead.geo.toUpperCase()}"></span> ${lead.geo.toUpperCase()} </div>`,
            leadStatus(lead.status),
            // lead.reason,
            lead.payout,
            leadSub(lead.sub1),
            leadSub(lead.sub2),
            leadSub(lead.sub3),
            leadSub(lead.sub4),
        ];
    }

    if (role === 'admin' || role === 'affiliate_manager') {
        const partnerName = lead.partner_name !== '' ? `<small style="margin-left: 4px;">(${lead.partner_name})</small>` : '';
        return [
            lead.created_at,
            lead.id,
            lead.external_uuid,
            `<a id="${lead.web_id}" style="cursor: pointer;" class="campaign-list-web-id">${lead.web_id}</a>${partnerName}`,
            lead.product_name,
            lead.obfuscated_phone_number,
            lead.obfuscated_name,
            lead.offer_type,
            `<div ><span class="fi fi-${lead.geo.toLowerCase()} fis flag-span" title="${lead.geo.toUpperCase()}"></span> ${lead.geo.toUpperCase()} </div>`,
            leadStatus(lead.status),
            // lead.reason,
            lead.payout,
            leadSub(lead.sub1),
            leadSub(lead.sub2),
            leadSub(lead.sub3),
            leadSub(lead.sub4),
        ];
    }

    return [
        lead.created_at,
        lead.id,
        lead.external_uuid,
        lead.web_id,
        lead.product_name,
        lead.offer_type,
        `<div ><span class="fi fi-${lead.geo.toLowerCase()} fis flag-span" title="${lead.geo.toUpperCase()}"></span> ${lead.geo.toUpperCase()} </div>`,
        leadStatus(lead.status),
        // lead.reason,
        lead.payout,
        leadSub(lead.sub1),
        leadSub(lead.sub2),
        leadSub(lead.sub3),
        leadSub(lead.sub4),
    ];
}
