<template>
  <div class="card card-filters">
    <!-- Card header -->
    <div class="card-header border-bottom">
      <div class="campaign d-flex align-items-center">
        <div class="col-6">
          <h5 class="mb-0">Filters</h5>
        </div>
      </div>
    </div>

    <!-- Card body -->
    <div class="card-body">
      <div class="row mt-sm-2">
        <LeadsDateFilter @update:updateFilters="updateFilters"/>

        <WebIDFilter @update:updateFilters="updateFilters"/>

        <UsersPartnerIDFilter @update:updateFilters="updateFilters"/>

        <GeoFilter @update:updateFilters="updateFilters"/>

        <ProductNameFilter @update:updateFilters="updateFilters"/>

        <OfferTypeFilter @update:updateFilters="updateFilters"/>

        <OfferIDFilter :offer-id="offerId" @update:updateFilters="updateFilters"/>
      </div>
    </div>
  </div>
</template>
<script>
import LeadsDateFilter from "@/components/Filters/LeadsDateFilter.vue";
import WebIDFilter from "@/components/Filters/WebIDFilter.vue";
import UsersPartnerIDFilter from "@/components/Filters/UsersPartnerIDFilter.vue";
import GeoFilter from "@/components/Filters/GeoFilter.vue";
import ProductNameFilter from "@/components/Filters/ProductNameFilter.vue";
import OfferTypeFilter from "@/components/Filters/OfferTypeFilter.vue";
import OfferIDFilter from "@/components/Filters/OfferIDFilter.vue";

export default {
  name: 'WebsDashboardFilters',
  components: {
    OfferIDFilter,
    OfferTypeFilter,
    ProductNameFilter, GeoFilter, UsersPartnerIDFilter, WebIDFilter, LeadsDateFilter},
  props: {
    updateFilters: {},
    offerId: {
      type: String,
      default: ''
    }
  }
}
</script>
