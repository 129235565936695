import eventTable from "@/mixins/eventTable.js"

const { actionDeleteButton, redirectToActiveCampaignsForOffer, landingPageLinks,actionRedirectToOfferPage, redirectToDashboardFilteredByOffer, actionRedirectToOfferLinkGenerate } = eventTable.methods;

export function getOfferProperties(offer, role) {
    const affiliate = role === 'affiliate';
    const admin = role === 'admin';
    const manager = role === 'affiliate_manager';

    if (affiliate) {
        return [
            offer.id,
            (null !== offer.image_url ? `<img src="${offer.image_url}" class="avatar-offer-xxl">` : ''),
            offer.name,
            offer.approve_rate . toString() + "%",
            offer.offer_type,
            offer.metric_type,
            `<div ><span class="fi fi-${offer.geo.toLowerCase()} fis flag-span" title="${offer.geo}"></span> ${offer.geo}</div>`,
            offer.niche,
            offer.price,
            offer.payout,
            actionRedirectToOfferPage(offer, "Show Offer")
        ];
    }

    if (manager) {
        return [
            offer.id,
            (null !== offer.image_url ? `<img src="${offer.image_url}" class="avatar-offer-xxl">` : ''),
            offer.name,
            offer.product_id,
            offer.approve_rate . toString() + "%",
            offer.offer_type,
            offer.metric_type,
            `<div ><span class="fi fi-${offer.geo.toLowerCase()} fis flag-span" title="${offer.geo}"></span> ${offer.geo}</div>`,
            offer.promoters,
            offer.niche,
            offer.price,
            offer.payout,
            landingPageLinks(offer.prelanding_link ?? '', offer.landing_link ?? ''),
            actionRedirectToOfferPage(offer, "Show Offer") + actionRedirectToOfferLinkGenerate(offer.id, "Generate Link")
        ];
    }

    if (admin) {
        return [
            offer.id,
            (null !== offer.image_url ? `<img src="${offer.image_url}" class="avatar-offer-xxl">` : ''),
            offer.name,
            offer.product_id,
            offer.approve_rate . toString() + "%",
            offer.offer_type,
            offer.metric_type,
            `<div ><span class="fi fi-${offer.geo.toLowerCase()} fis flag-span" title="${offer.geo}"></span> ${offer.geo}</div>`,
            offer.promoters,
            offer.niche,
            offer.price,
            offer.payout,
            landingPageLinks(offer.prelanding_link ?? '', offer.landing_link ?? ''),
            actionRedirectToOfferPage(offer, "Show Offer") + actionRedirectToOfferLinkGenerate(offer.id, "Generate Link") + actionDeleteButton(offer.id, "Delete offer")
        ];
    }

    return [
        offer.id,
        (null !== offer.image_url ? `<img src="${offer.image_url}" class="avatar-offer-xxl">` : ''),
        offer.name,
        offer.approve_rate . toString() + "%",
        offer.offer_type,
        offer.metric_type,
        `<div ><span class="fi fi-${offer.geo.toLowerCase()} fis flag-span" title="${offer.geo}"></span> ${offer.geo}</div>`,
        offer.promoters,
        offer.niche,
        offer.price,
        offer.payout,
        redirectToActiveCampaignsForOffer(offer.id, "Active campaigns") + redirectToDashboardFilteredByOffer(offer.id, "Dashboard for this offer") + actionRedirectToOfferPage(offer, "Show Offer")
    ];
}
