/* eslint-disable no-unused-vars */
import usersService from '../services/users.service';

const initialState = {
    users: null,
    user: null,
    bestWebs: null,
    affiliateManagers: null,
    usersIds: null,
    partnerIds: null,
    partners: null,
    affiliateManagersIds: null,
    websIdsWithNames: null,
    websWithAccess: null
};

export const users = {
    namespaced: true,
    state: initialState,
    actions: {
        async getUsers({commit}, options) {
            const users = await usersService.getUsers(options);
            commit('getUsersSuccess', users);
        },

        async getAffiliateManagersData({commit}, options) {
            if (null == this.state.users.affiliateManagers) {
                const affiliateManagers = await usersService.getAffiliateManagers(options);
                commit('getAffiliateManagersSuccess', affiliateManagers);
            }
        },

        async getPartnersData({commit}, options) {
            if (null == this.state.users.partners) {
                const partners = await usersService.getPartners(options);
                commit('getPartnersSuccess', partners);
            }
        },

        async getUsersIds({commit}, options) {
            // console.log(options);
            if (null == this.state.users.usersIds || undefined !== options) {
                const usersIds = await usersService.getUserIds(options);
                commit('getUsersIdsSuccess', usersIds);
            }
        },

        async getPartnerIds({commit}, options) {
            if (null == this.state.users.partnerIds) {
                const usersIds = await usersService.getPartnerIds(options);
                commit('getPartnerIdsSuccess', usersIds);
            }
        },

        async getAffiliateManagerIds({commit}, options) {
            if (null == this.state.users.affiliateManagersIds) {
                const usersIds = await usersService.getAFfiliateManagersIds(options);
                commit('getAffiliateManagersIdsSuccess', usersIds);
            }
        },

        async getWebsWithAccess({commit}, offerId) {
            const webs = await usersService.getWebsWithAccess(offerId);
            commit('getWebsWithAccessSuccess', webs);
        },

        async getWebsIdsWithNames({commit}, options) {
            const usersIds = await usersService.getWebsIdsWithNames(options);
            commit('getWebsSuccess', usersIds);
        },

        async getWebsForDashboard({commit}, options) {
            const users = await usersService.getUsersForDashboard(options);
            commit('getUsersSuccess', users);
        },

        async getBestWebs({commit}, options) {
            const bestWebs = await usersService.getBestWebs(options);
            commit('getBestWebsSuccess', bestWebs);
        },

        async addUser({commit}, newUser) {
            const user = await usersService.addUser(newUser);
            commit('getUserSuccess', user);
        },

        async deleteUser({commit}, userId) {
            await usersService.deleteUser(userId);
        },

        async getUser({commit}, userId) {
            const user = await usersService.getUser(userId);
            commit('getUserSuccess', user);
        },

        async editUser({commit}, modifiedUser) {
            await usersService.editUser(modifiedUser);
        },

        async uploadPic({commit}, file) {
            const picURL = (await usersService.uploadPic(file, this.state.users.user.id)).url;
            commit('successUpload', picURL);
        },
    },
    mutations: {
        getUsersSuccess(state, users) {
            state.users = users;
        },
        getAffiliateManagersSuccess(state, affiliateManagers) {
            state.affiliateManagers = affiliateManagers;
        },
        getPartnersSuccess(state, partners) {
            state.partners = partners;
        },
        getWebsSuccess(state, webs) {
            state.websIdsWithNames = webs;
        },
        getUsersIdsSuccess(state, usersIds) {
            state.usersIds = usersIds;
        },
        getAffiliateManagersIdsSuccess(state, usersIds) {
            state.affiliateManagersIds = usersIds;
        },
        getPartnerIdsSuccess(state, usersIds) {
            state.partnerIds = usersIds;
        },
        getWebsWithAccessSuccess(state, websWithAccess) {
            state.websWithAccess = websWithAccess;
        },
        getBestWebsSuccess(state, bestWebs) {
            state.bestWebs = bestWebs;
        },
        successUpload(state, picURL) {
            state.user.profile_image = picURL;
        },
        getUserSuccess(state, user) {
            state.user = user;
        }
    },
    getters: {
        getUsersData(state) {
            return state.users?.data;
        },
        getAffiliateManagersData(state) {
            return state.affiliateManagers?.data;
        },
        getPartnersData(state) {
            return state.partners?.data;
        },
        getWebsIdsWithNames(state) {
            return state.websIdsWithNames?.data;
        },
        getBestWebsData(state) {
            return state.bestWebs?.data;
        },
        getUsersIds(state) {
            return state.usersIds?.data;
        },
        getPartnersIds(state) {
            return state.partnerIds?.data;
        },
        getAffiliateManagersIds(state) {
            return state.affiliateManagersIds?.data;
        },
        getUsersMeta(state) {
            return state.users?.meta;
        },
        getUser(state) {
            return state.user
        },
        getImage(state) {
            return state.user.profile_image
        },
        getWebsWithAccess(state){
            return state.websWithAccess?.data;
        },
    }
}

